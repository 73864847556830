import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import campaignAPIService from '../../services/api/campiagn-api';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { CSVLink } from "react-csv";
import Tooltip from '@mui/material/Tooltip';
import DownloadIcon from '@mui/icons-material/Download';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import { Button } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import LoopIcon from "@mui/icons-material/Refresh";
import { createStyles, makeStyles } from "@material-ui/core";

let tableProps = {
    mt: 3,
    maxHeight: 420,
    "&::-webkit-scrollbar": { width: 3 },
    "&::-webkit-scrollbar-track": { backgroundColor: "stalegrey" },
    "&::-webkit-scrollbar-thumb": { backgroundColor: "darkgrey", borderRadius: 2 }
}
export const useStyles = makeStyles(() =>
    createStyles({
        rotateIcon: {
            animation: "$spin 2s linear infinite"
        },
        "@keyframes spin": {
            "0%": {
                transform: "rotate(360deg)"
            },
            "100%": {
                transform: "rotate(0deg)"
            }
        }
    })
);
export default function CampaignUsers({ campaign }) {
    const classes = useStyles();

    let [campaignUsers, setCampaignUsers] = useState([{}]);
    let [downloadData, setDownloadData] = useState([]);
    let [refreshData, setRefreshData] = useState(true);
    let [loadingMsg, setLoadingMsg] = useState(true);
    let [rotateRefresh, setRotateRefresh] = useState([true]);
    function handleRefresh() {
        setRefreshData(!refreshData);
    }
    useEffect(() => {
        const getData = async () => {
            if (campaign.id) {
                setRotateRefresh(true)
                setLoadingMsg(true);
                let data = await getCampiagnUsersData(campaign.id);
                let users = getDownloadedData(data)
                setDownloadData(users);
                setCampaignUsers(data);
                setLoadingMsg(false);
                setRotateRefresh(false)

            }
        };
        getData();
    }, [campaign, refreshData]);
    return (
        <>


            {campaign && campaign.id &&
                <Box sx={{ mt: 5, minWidth: "50%" }}>
                    <Toolbar />
                    <Box display="flex" justifyContent="space-between"
                        alignItems="center">
                        <>


                            <h3>
                                {campaign.name}</h3>
                            <Box>
                                {campaignUsers.length > 0 &&
                                    <CSVLink filename={campaign.name} data={downloadData}>
                                        <Tooltip title="Download Report" arrow>
                                            <DownloadIcon style={{ color: "black" }} />
                                        </Tooltip>
                                    </CSVLink>
                                }
                                {!rotateRefresh &&
                                    <Tooltip title="Refresh Campaigns">
                                        <RefreshIcon onClick={handleRefresh} style={{ color: "black" }} />
                                    </Tooltip>
                                }
                                {rotateRefresh && <LoopIcon className={classes.rotateIcon} />}
                            </Box>

                        </>



                    </Box>


                    {!rotateRefresh && campaignUsers.length > 0 &&

                        <Box>
                            <Paper >
                                <TableContainer sx={tableProps}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: "bold" }} align="center">Email</TableCell>
                                                <TableCell sx={{ fontWeight: "bold" }} align="center">Clicked</TableCell>
                                                <TableCell sx={{ fontWeight: "bold" }} align="center">Send Material</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {campaignUsers.map((row, index) => (
                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                                    <TableCell align="center" style={{ width: 50 }} >{row.email}</TableCell>
                                                    <TableCell align="center">
                                                        {row.is_clicked === 1 ? <CheckIcon style={{ color: "green", fontSize: "medium" }} /> : <CloseIcon style={{ color: "red", fontSize: "medium" }} />}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ width: 50 }} >
                                                        <Checkbox
                                                            checked={row.checked}
                                                            onChange={(e) => handleChange(row, e)}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                            <Box textAlign='center'>
                                <Button sx={{ mt: 5 }} onClick={sendMaterial} style={{ textTransform: 'none' }} variant="contained">
                                    Send Material
                                </Button>
                            </Box>
                        </Box>
                    }
                    {campaignUsers.length === 0 &&
                        <div align="center"> No users Added </div>
                    }


                </Box>
            }
        </>
    );

    function getCampiagnUsersData(id) {
        return campaignAPIService.getCampaignUsers(id).then(function (response) {
            return response.data;
        })
    }

    function getDownloadedData(userData) {
        let report = []
        userData.map(user => {
            report.push({ Name: user.first_name + " " + user.last_name, Email: user.email, "Campaign results": user.is_clicked ? 'Fail' : 'Pass' })
        })
        return report;
    }
    function handleChange(row, event) {
        campaignUsers.forEach(function (user) {
            if (user.email == row.email)
                user.checked = event.target.checked;
        });
        setCampaignUsers(campaignUsers);
    }
    function sendMaterial() {
        let userEmails = [];
        campaignUsers.forEach(function (user) {
            if (user.checked) {
                userEmails.push(user.email);
            }
        })
        if (userEmails.length > 0) {
            sendMaterialEmail(userEmails);
        } else {
            alert("Select atleast one user to send material")
        }
    }
    function sendMaterialEmail(emails) {
        campaignAPIService.sendMaterialEmail(emails).then(function (response) {
            handleRefresh();
            alert("Material sent successfully");
        })
    }

}




