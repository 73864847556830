import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import PhishingIcon from '@mui/icons-material/Phishing';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import { useEffect, useState } from 'react';
import jwt from 'jwt-decode';
const drawerWidth = 250;


export default function SideNav() {
let [user, setUser] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        setUser(token ? jwt(token) : {});
    },[])
    return (
        <Drawer
            variant="permanent"
            PaperProps={{ sx: { backgroundColor: "#14354b", color: "white" } }}
            sx={{ width: drawerWidth, flexShrink: 0, [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' } }}
        >
            <Toolbar />
            <Box sx={{ overflow: 'auto' }}>
                <List>
                <ListItem key="user" disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <AccountCircleIcon  style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={user.email} />
                            <Tooltip title="Logout" arrow placement="left">
                            <ListItemIcon sx={{ minWidth:0}} onClick={() => logOut()}>
                                <LogoutIcon  style={{ color: "white" }} />
                            </ListItemIcon>
                            </Tooltip>

                        </ListItemButton>
                    </ListItem>
                    {['My Phishing Campaigns'].map((text, index) => (
                        <ListItem key={text} disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                     <PhishingIcon style={{ color: "white" }} /> 
                                </ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Drawer>

    );

    function logOut() {
        if (window.confirm("Are you sure you want to logout ? ")) {
            localStorage.clear();
            window.location.href = '/login'
        }
        

    }
}