import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
export default function Header() {
    return (
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar style={{ backgroundColor: "white", color: "#14354b" }}>
                <Link href="/">
                    <Box
                        component="img"
                        sx={{ height: 54 }}
                        alt="Logo"
                        src="/logo.svg"
                    />
                </Link>
            </Toolbar>
        </AppBar>
    );
}