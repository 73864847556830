
// @ts-ignore
import Header from "../components/header/header";
import SideNav from "../components/sidenav/sidenav";
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Campaign from "../components/campaign/campaign";

const theme = createTheme({
  palette: {
    primary: {
      main: '#14354b',
    }
  },
});

export function PhishingCampiagn() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: 'flex' }}>
          <SideNav></SideNav>
          <Header></Header>
          <Campaign></Campaign>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default PhishingCampiagn;