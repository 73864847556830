import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LockIcon from '@mui/icons-material/Lock';
const theme = createTheme({
    palette: {
        primary: {
            main: '#14354b',
        }
    },
});

export default function Login() {
    const handleSubmit = () => {
        window.location.href = 'https://soteria-portal.auth.us-east-2.amazoncognito.com/oauth2/authorize?response_type=code&client_id=251jknhpnag6go3qtet8uv73r3&redirect_uri='+window.location.origin +'/authorize';
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 18,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        component="img"
                        sx={{ height: 100 }}
                        alt="Logo"
                        src="/logo.svg"
                    />
                    <Box component="form" noValidate sx={{ mt: 5 }}>
                        <Button onClick={handleSubmit} style={{ textTransform: 'none' }} startIcon={<LockIcon />} variant="contained">
                          
                            Login to Soteria Portal
                        </Button>
                    </Box>
                </Box>

            </Container>
        </ThemeProvider>
    );
}