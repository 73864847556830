import * as React from 'react';
import authAPIService from '../../services/api/auth'
import { useEffect } from 'react';
export default function Auth() {
    var url = new URLSearchParams(window.location.search);
    var code = url.get('code')


    useEffect(() => {
        const getData = async () => {
            let data = await getTokenData(code);
            if (data.status == 200) {
                localStorage.setItem('token', JSON.stringify(data.data.id_token));
                window.location.href = '/phishing-campaigns'
            } else {
                window.location.href = '/login'
            }

        };
        getData();
    });


    return (
        <>You are being redirected.....</>
    );

    function getTokenData(code) {
        return authAPIService.getToken(code).then(function (response) {
            return response;
        })
    }
}