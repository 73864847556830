import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import campaignAPIService from '../../services/api/campiagn-api';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography'
import CampaignUsers from './campaign-users';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CreateCampaign from './create-campaign';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import Tooltip from '@mui/material/Tooltip';
import { createStyles, makeStyles } from "@material-ui/core";
import LoopIcon from "@mui/icons-material/Refresh";
import * as moment from 'moment';
let tableProps = {
    mt: 3,
    maxHeight: 440,
    "&::-webkit-scrollbar": { width: 3 },
    "&::-webkit-scrollbar-track": { backgroundColor: "stalegrey" },
    "&::-webkit-scrollbar-thumb": { backgroundColor: "darkgrey", borderRadius: 2 }
}
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export const useStyles = makeStyles(() =>
    createStyles({
        rotateIcon: {
            animation: "$spin 2s linear infinite"
        },
        "@keyframes spin": {
            "0%": {
                transform: "rotate(360deg)"
            },
            "100%": {
                transform: "rotate(0deg)"
            }
        }
    })
);
export default function Campaign() {
    const classes = useStyles();
    let [campaigns, setCampaigns] = useState([]);
    let [refreshData, setRefreshData] = useState(true)
    let [selectedCampaign, setSelectedCampaign] = useState({});
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    let [showLoader, setShowLoader] = useState([true]);
    let [rotateRefresh, setRotateRefresh] = useState([true]);
    function handleRefresh() {
        setSelectedCampaign({})
        setRotateRefresh(true);
        handleClose();
        setRefreshData(!refreshData);
    }
    useEffect(() => {
        setCampaigns([]);
        if (!localStorage.getItem('token')) {
            window.location.href = '/login'
        }
        const getData = async () => {
            let data = await getCampiagnData();
            data = data.sort((a, b) => a.id < b.id ? 1 : -1);
            setCampaigns(data);
            setShowLoader(false);
            setRotateRefresh(false);
        };
        getData();
    }, [refreshData]);
    return (
        <>
            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        <Box display="flex" alignItems="center">
                            <Box flexGrow={1} >
                                <Typography variant="h6" align="left" gutterBottom>
                                    Create Campaign
                                </Typography>
                            </Box>
                            <Box>
                                <CloseIcon onClick={handleClose} style={{ color: "black" }} />

                            </Box>
                        </Box>
                        <CreateCampaign closeCreateModal={() => { handleRefresh() }}></CreateCampaign>
                    </Box>
                </Modal>
            </div>
            <Grid container spacing={1} >
                <Box sx={{ m: 5, minWidth: "40%" }}>
                    <Toolbar />
                    <Box display="flex" justifyContent="space-between"
                        alignItems="center">
                        <h3 > Campaign List</h3>
                        <>
                            {!rotateRefresh && <Tooltip title="Refresh Campaigns">
                                <RefreshIcon onClick={handleRefresh} style={{ color: "black", }} />
                            </Tooltip>
                            }
                            {rotateRefresh && <LoopIcon className={classes.rotateIcon} />}
                        </>
                    </Box>

                    {campaigns && campaigns.length > 0 &&
                        <>
                            <Box>
                                <TableContainer sx={tableProps}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow >
                                                <TableCell sx={{ fontWeight: "bold" }} align="center">Campaign</TableCell>
                                                <TableCell sx={{ fontWeight: "bold", whitespace: "nowrap" }} align="center">EndDate</TableCell>
                                                <TableCell sx={{ fontWeight: "bold" }} align="center">User Count</TableCell>
                                                <TableCell sx={{ fontWeight: "bold" }} align="center">status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {campaigns.map((row, index) => (
                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                                    <TableCell align="center" sx={{ cursor: 'pointer', fontWeight: "bold" }} onClick={() => showCampaignUsers(row)} >{row.name}</TableCell>
                                                    <TableCell sx={{ whitespace: "nowrap" }} align="center">{row.end_date ? formatDate(row.end_date) : '-'}</TableCell>
                                                    <TableCell align="center">{row.users_count}</TableCell>
                                                    <TableCell align="center" style={{ textTransform: 'capitalize' }} >{row.status}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                           
                        </>
                        
                    }
                     <Box textAlign='center' >
                         <Button sx={{ mt: 3 }} onClick={handleOpen} style={{ textTransform: 'none' }} variant="contained">
                             Create New Campiagn
                         </Button>
                     </Box>

                    {/* {campaigns.length === 0 && <div>Fetching Data </div>} */}
                    {/* {showLoader && <LoopIcon className={classes.rotateIcon} />} */}


                </Box>
                <Box sx={{ minWidth: "40%" }}>
                    <CampaignUsers campaign={selectedCampaign}></CampaignUsers>
                </Box>
            </Grid>
        </>
    );

    function getCampiagnData() {
        return campaignAPIService.getCampaignList().then(function (response) {
            return response.data;
        })
    }
    function formatDate(input) {
        // let objectDate = new Date(input);
        var local = moment(input).local().format('MM-DD-YYYY HH:mm');
        // return (objectDate.getMonth() + 1) + "-" + objectDate.getDate() + "-" + objectDate.getFullYear();
        return local;
    }
    function showCampaignUsers(campaign) {
        // if (campaign.status !== "completed") {
        //     completeCampaign(campaign.id);
        //     setRefreshData(campaign.id)
        // }
        setSelectedCampaign(campaign)
    }
    function completeCampaign(id) {
        return campaignAPIService.completeCampaign(id).then(function (response) {
            return response.data;
        })
    }
}




