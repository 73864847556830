import axios from 'axios';
import jwt from 'jwt-decode';
const token = localStorage.getItem('token');
const user = token ? jwt(token) : {};
const campaignAPIService = {
    getCampaignList: function () {
        const config = {
            method: 'post',
            data : {"action":"campaign_list","key":user.sub},
            url: 'https://1ehyrlg4d7.execute-api.us-east-2.amazonaws.com/v1/reports',
        };
        return axios(config)
            .then(function (response) {
                return response.data;

            })
            .catch(function (error) {
                console.log(error);
            });
    },
    getCampaignUsers: function (id) {
        const config = {
            method: 'post',
            data : {
                "action": "campaign_users",
                "key": user.sub,
                "campaign_id": id
            },
            url: 'https://1ehyrlg4d7.execute-api.us-east-2.amazonaws.com/v1/reports',
        };
        return axios(config)
            .then(function (response) {
                return response.data;

            })
            .catch(function (error) {
                console.log(error);
            });
    },
    completeCampaign: function (id) {
        const config = {
            method: 'post',
            data : {
                "action": "campaign_complete",
                "key": user.sub,
                "campaign_id": id
            },
            url: 'https://1ehyrlg4d7.execute-api.us-east-2.amazonaws.com/v1/reports',
        };
        return axios(config)
            .then(function (response) {
                return response.data;

            })
            .catch(function (error) {
                console.log(error);
            });
    },
    sendMaterialEmail: function (users) {
        const config = {
            method: 'post',
            data : {
                "action": "send_material",
                "key": user.sub,
                "emails": users
            },
            url: 'https://1ehyrlg4d7.execute-api.us-east-2.amazonaws.com/v1/reports',
        };
        return axios(config)
            .then(function (response) {
                return response.data;

            })
            .catch(function (error) {
                console.log(error);
            });
    },
    createCampaign: function (campaign) {
        const config = {
            method: 'post',
            data : {
                "action": "campaign_create",
                "key": user.sub,
                "data": campaign.data,
                "name": campaign.name,
                "end_date" : campaign.endDate
            },
            url: 'https://1ehyrlg4d7.execute-api.us-east-2.amazonaws.com/v1/reports',
        };
        return axios(config)
            .then(function (response) {
                return response.data;

            })
            .catch(function (error) {
                console.log(error);
            });
    },

 
}

export default campaignAPIService;