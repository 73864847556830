import { Button,  FormControlLabel, FormLabel, makeStyles, TextField, Typography } from "@material-ui/core"
import campaignAPIService from '../../services/api/campiagn-api';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { CSVLink } from "react-csv";
import * as moment from 'moment';


export default function CreateCampaign(props) {
    let [campaignUsers, setCampaignUsers] = useState('');
    let [tabIndex, setTabIndex] = useState(1);
    let [fileName, setFileName] = useState('No File Chosen');
    const yesterday = moment().subtract(1, 'day');
    const disablePastDt = current => {
        return current.isAfter(yesterday);
    };

    let btnStyle = { textTransform: 'none', marginTop: "30px", background: "#14354b", color: "white" }
    let fileLabelStyle = { padding: "10px", textTransform: 'none', marginTop: "30px", background: "#14354b", color: "white" }

    let templateData = [
        ["user01@mail.com;Name;Surname"],
        ["user02@mail.com;Name;Surname"],
        ["user03@mail.com;Name;Surname"]

    ]
    useEffect(() => {

    }, [tabIndex]);
    const [formValues, setFormValues] = useState({
        name: {
            value: '',
            error: false,
            errorMessage: 'Campaign Name is required'
        },
        endDate: {
            value: '',
            error: false,
            errorMessage: 'Campaign End Date is required'
        }
    })

    const handleChange = (e) => {
        const { name, value, error } = e.target;
        setFormValues({
            ...formValues,
            [name]: {
                ...formValues[name],
                value,
                error
            }
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();

        const formFields = Object.keys(formValues);
        let newFormValues = { ...formValues }

        for (let index = 0; index < formFields.length; index++) {
            const currentField = formFields[index];
            const currentValue = formValues[currentField].value;

            if (currentValue === '') {
                newFormValues = {
                    ...newFormValues,
                    [currentField]: {
                        ...newFormValues[currentField],
                        error: true
                    }
                }
            }

        }
        setFormValues(newFormValues);
        let campaignData = {}
        if (campaignUsers === '') {
            alert("Please select Campaign Users");
        } else if (!formValues.name.value) {
            alert("Please fill Campaign Name");
        } else if (!formValues.endDate.value) {
            alert("Please fill Campaign End Date");
        }
        else {
            campaignData = {
                name: formValues.name.value,
                endDate: new Date(formValues.endDate.value).toISOString(),
                data: campaignUsers
            }
            createCampaign(campaignData);
            setTimeout(function () {
                props.closeCreateModal()
            }, 2000);

        }

    }

    function handleCapture({ target }) {
        const fileReader = new FileReader();
        const reader = new FileReader()
        reader.onload = () => {
            console.log(reader.result)
        }
        fileReader.readAsText(target.files[0]);
        setFileName(target.files[0].name);
        fileReader.onload = (e) => {
            setCampaignUsers(e.target.result);
        };
    };
    function createCampaign(campaign) {
        return campaignAPIService.createCampaign(campaign).then(function (response) {
            return response.data;
        })
    }
    return (
        <div>
            <form  onSubmit={handleSubmit} >
                {tabIndex === 1 && <>
                    <Box textAlign='center'>
                        <div style={{ marginTop: "30px" }}>Download .CSV example </div>
                        <CSVLink filename="csv_template" data={templateData}>
                            <Button style={btnStyle} variant="contained">
                                Download .CSV
                            </Button>
                        </CSVLink>
                    </Box>
                    <Box display="flex"
                        justifyContent="flex-end"
                        alignItems="flex-end">
                        <Button onClick={() => { setTabIndex(2) }} style={btnStyle} variant="contained">
                            Next
                        </Button>
                    </Box>
                </>
                }
                {tabIndex === 2 &&
                    <>
                        <Box textAlign='center'>
                            <div style={{ margin: "30px" }}>Please select file with emails to import</div>
                            <label htmlFor="upload-users" style={fileLabelStyle}>
                                Choose File
                            </label>
                            <span style={{ padding: "9px", border: "gray solid 1px" }}>{fileName}</span>
                            <input style={btnStyle}
                                type="file"
                                accept=".csv"
                                hidden
                                id="upload-users"
                                onChange={handleCapture}
                            />
                        </Box>
                        <br />
                        <Box display="flex" justifyContent="space-between"
                            alignItems="center">
                            <Button onClick={() => { setTabIndex(1) }} style={btnStyle} variant="contained">
                                Back
                            </Button>
                            <Button onClick={() => { setTabIndex(3) }} style={btnStyle} variant="contained">
                                Next
                            </Button>
                        </Box>
                    </>

                }
                {tabIndex === 3 &&
                    <>
                        <TextField style={{ marginBottom: "30px" }}
                            placeholder="Campaign Name"
                            label="Campaign Name"
                            name="name"
                            fullWidth
                            required
                            selected={formValues.name.value}
                            value={formValues.name.value}
                            onChange={handleChange}
                            error={formValues.name.error}
                            helperText={formValues.name.error && formValues.name.errorMessage}
                        />
                        <TextField style={{ marginBottom: "30px" }}
                            name="endDate"
                            id="date"
                            inputProps={{
                            min: new Date().toISOString().slice(0, 16) }}
                            label="Campaign End Date"
                            type="datetime-local"
                            fullWidth
                            required
                            value={formValues.endDate.value}
                            onChange={handleChange}
                            error={formValues.endDate.error}
                            helperText={formValues.endDate.error && formValues.endDate.errorMessage}
                            InputLabelProps={{
                                shrink: true,
                            }}

                        />
                        <Box display="flex" justifyContent="space-between"
                            alignItems="center">
                            <Button onClick={() => { setTabIndex(2) }} style={btnStyle} variant="contained">
                                Back
                            </Button>
                            <Button style={btnStyle} type="submit" variant="contained">
                                Create Campaign
                            </Button>
                        </Box>
                    </>
                }
            </form>
        </div>
    )
}









