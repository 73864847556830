import './App.css';
import Login from './components/login/login';
import Auth from './components/auth/auth';
import { PhishingCampiagn } from './pages/phishing-campaign';
import * as React from 'react';
import { BrowserRouter as Router, useRoutes } from "react-router-dom";

const App = () => {
  let routes = useRoutes([
    { path: "/", element: <Login /> },
    { path: "/login", element: <Login /> },
    { path: "/authorize", element: <Auth /> },
    { path: "/phishing-campaigns", element: <PhishingCampiagn /> }
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWrapper;
