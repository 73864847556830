import axios from 'axios';
import jwt from 'jwt-decode';

const qs = require('qs')
let base64 = require('base-64');

const token = localStorage.getItem('token');
const user = token ? jwt(token) : {};

const authAPIService = {

    getToken: function (code) {
        const params = {
            redirect_uri: window.location.origin + '/authorize',
            grant_type: 'authorization_code',
            clientId: '251jknhpnag6go3qtet8uv73r3',
            code: code
        }
        var basicAuth = 'Basic ' + base64.encode("251jknhpnag6go3qtet8uv73r3" + ":" + "atb5g4lrs39qmkovvipvdgkk18670ae7tbt5aonse5fduiho5m9")
        const url = 'https://soteria-portal.auth.us-east-2.amazoncognito.com/oauth2/token';
        const options = {
            method: 'post',
            headers: {
                'Authorization': basicAuth,
                'content-type': 'application/x-www-form-urlencoded'
            },
            data: qs.stringify(params),
            url
        }

        return axios(options).then(function (response) {
            return response;

        })
        .catch(function (error) {
            return error;
        });
    },
    getProfile: function () {
        const config = {
            method: 'post',
            data : {
                "action": "get_profile",
                "key": user.sub,
            },
            url: 'https://1ehyrlg4d7.execute-api.us-east-2.amazonaws.com/v1/reports',
            // headers: { Authorization: API.token },
        };
        return axios(config)
            .then(function (response) {
                return response.data;

            })
            .catch(function (error) {
                console.log(error);
            });
    },


}

export default authAPIService;